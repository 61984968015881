import React, { useEffect } from "react";
import Layout from "../components/layout";
import Form from "../components/form";
import Link from "gatsby-link";
import SEO from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const ContactPage = () => {
  useEffect(() => {
    document.getElementById("lang-switch").href = "/pl/kontakt/";
  }, []);

  function Img1() {
    return (
      <StaticImage
        src="../images/ba1.jpg"
        alt=""
        placeholder="blurred"
        layout="constrained"
        style={{
          gridArea: "1/1",
          borderRadius: "15px",
          maxWidth: "400px",
        }}
        imgClassName="card"
        formats={["auto", "webp", "avif"]}
      />
    );
  }
  return (
    <>
      <SEO
        title={"Contact us!"}
        description={""}
        header={{ siteTitle: " Shroom | Shop" }}
      />
      <Layout>
        <div
          className="page-container container my-5"
          style={{ textAlign: "center" }}
        >
          <h1 className="spec-h">Contact Page</h1>
          <p>We invite You to contact us.</p>
          <p>You can use the contact form below, or send us an e-mail!</p>
          <br />
          <div className="row">
            <div className="col card" id="c-form" style={{ minWidth: "180px" }}>
              <Form />
            </div>
            <div className="col" style={{ textAlign: "center" }}>
              <div className="card" style={{ borderRadius: "15px" }}>
                <h2
                  className="mt-5 mb-4 spec-h"
                  style={{
                    textAlign: "center",
                    maxWidth: "80%",
                    marginLeft: "10%",
                  }}
                >
                  Do You need
                  <br /> anything else?
                </h2>
                <h3 style={{ padding: "15px", fontSize: "18px" }}>
                  Feel free to send an email!
                </h3>
                <br />
                <p style={{ textAlign: "center" }} className="mb-2">
                  <a
                    className="btn btn-primary"
                    style={{ color: "white" }}
                    href="mailto:hii@shroom4you.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hii@shroom4you.com
                  </a>
                </p>
                <p
                  style={{ padding: "15px", maxWidth: "400px", margin: "auto" }}
                >
                  Did you find a <b>faulty function or other bug</b>?<br /> Help
                  us determine the cause and solve the problem by completing the{" "}
                  <b>
                    <a
                      style={{ color: "#0d6efd" }}
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfjoHBFcO_ne1liGDAhM_JPNoVAK9uK5_xQCbqAvDm2wSKkGQ/viewform"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      technical form
                    </a>
                  </b>
                  .
                </p>
                <br />
              </div>
              <br />
              <p style={{ textAlign: "center" }}>
                Grab your <span className="spec-h">shroom</span> drinks today!
                <br />
                <br />
                <Link to="/">{Img1()}</Link>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ContactPage;
